export default [
  {
    header: 'Pages',
    children: [
      {
        title: 'Home',
        route: 'home',
        icon: 'estate',
      }
    ],
  },
]
