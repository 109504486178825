<template>
    <div class="mr-2">
        <span class="d-inline-block py-25 language-name navLink" @click="changeLange(locales[+activeIndex].locale)">
            {{locales[+activeIndex].name}}
        </span>
    </div>
</template>
<style lang="scss">
@import "src/assets/scss/variables/variables.scss";
    .isActive,
    .navLink {
        color: #fff !important;
        font-size: 18px;
        opacity: .6;
    }

        .isActive, .navLink:hover {
            opacity: 1;
            color: $secondaryPrimary !important;
        }
    .language-name {
        padding-right: 1.5rem !important;
        padding-left: 1.5rem !important;
        cursor: pointer;
    }
</style>
<script>
import store from '@/store'
import { getLang, setLang } from "@/libs/i18n/index.js";
import globalEvent from "@/libs/global-event" 

export default {
    computed: {
        currentLocale() {
            return this.locales.find(l => l.locale === this.$i18n.locale);
        }
    },
    data: () => ({
        activeIndex: 0
    }),
    setup() {
        const locales = [
            {
                locale: "en",
                name: "English",
                dir: "ltr"
            },
            {
                locale: "ar",
                name: "العربية",
                dir: "rtl"
            }
        ];
        return {
            locales
        };
    },
    methods: {
        changeLange(locale) {
            this.activeIndex = !this.activeIndex
            setLang(locale)
            this.$i18n.locale = locale
            store.commit('appConfig/TOGGLE_RTL')
            globalEvent.changeLang()
        }
    },
    mounted() {
        this.changeLange(getLang())
        this.activeIndex = +!(this.currentLocale.locale == 'ar')
        
    }
};
</script>
